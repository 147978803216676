import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TortulPage = () => (
  <Layout>
    <SEO title="Cum alegem tortul?" />
    <div className="drawer article-content">
      <h1 className="article-title">Cum alegem tortul?</h1>

      <section>
        <p>
          Când te gândești la o nuntă, de obicei și tăiatul tortului este unul
          dintre momentele pe care ți le imaginezi, tocmai pentru că este unul
          din acele momente cheie.
        </p>
        <p>
          Dintre atâtea modele și tot atâtea gusturi, cum să faci totuși
          alegerea potrivită ?
        </p>
        <p>
          Gândește-te la povestea nunții voastre, tortul este și el parte din
          poveste și îl vrei integrat în acest cadru.
        </p>
      </section>

      <img src="https://images.unsplash.com/photo-1596567503289-6c12aed24902?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=343&q=80" />

      <h3 className="paragh-title">
        Așadar, câteva aspecte de luat în calcul pentru alegerea tortului
        potrivit:
      </h3>
      <section>
        <ul>
          <li>
            Ar trebui să iei în calcul și anotimpul. Dacă este vară încearcă să
            renunți la multe creme și să te gândești la o variantă cu fructe.
          </li>
          <li>
            <p>
              Ia în calcul și numărul de invitați. Cu siguranță îl vei lua și
              când va fi nevoie să decizi asupra cantității, însă și în acest
              moment este necesar să te gândești, în special în cazul în care
              voiai un tort mai deosebit.
            </p>
            <p>
              De exemplu, pavlova este mai dificil de tăiat și în cazul în care
              ai 300 de invitați probabil ar fi destul de greu să gestionezi
              acest lucru.
            </p>
            <p>
              Legat de cantitate, de obicei cofetăriile sugereaza o felie de 100
              grame/invitat, însă va trebui să iei în calcul că nu toți
              invitații vor râmâne până la momentul tăierii tortului.
            </p>
          </li>
          <li>
            Este imposibil să poți mulțumi pe toată lumea cu o singură aromă și
            acest lucru este clar. Încearcă totuși să alegi arome clasice și
            astfel mergi la sigur
          </li>
          <li>
            Poți opta și pentru opțiunea de prăjituri individuale pentru
            invitați și să aveți doar un mic tort pe care să îl tăiați
          </li>
          <li>
            Legat de decorul tortului: va trebui integrat în temă, însă nu uita
            că este important să îți placă ce alegi
          </li>
        </ul>
      </section>

      <section>
        <p>
          Poți alege un model simplu și deosebit (torturile naked/dezbrăcate fie
          cu fructe proaspete, fie cu flori se pot integra în orice decor
          personalizându-le diverse elemente) și poți transforma tăiatul
          tortului într-un moment unic prin artificii (sparkles sau artficiile
          mici care se țin în mână), baloane cu heliu, lampioane, o melodie
          diferită (de obicei, la tăiatul tortului, melodia este “La mulți ani”)
          sau orice altă idee care este potrivită pentru voi.
        </p>
      </section>

      <div className="nav-section">
        <Link to="/marturiile">◀ Mărturiile </Link>
        <Link to="/mancarea">▶ Mâncarea de la nuntă</Link>
      </div>
    </div>
  </Layout>
)

export default TortulPage
